<template>
  <BRow>
    <BCol lg="12">
      <BCard>
        <span class="font-medium text-black text-2xl">Performa Pertumbuhan User (Partner)</span>
        <div class="mt-1 grid grid-cols-4 grid-rows-2 gap-2">
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="@/assets/images/svg/note.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Registrasi</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.Registrasi.total_register }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.Registrasi.register_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.Registrasi.register_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="@/assets/images/svg/tick-square.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Verifikasi</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.verifikasi.total_verified }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.verifikasi.verified_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.verifikasi.verified_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/onboarding.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Onboarding</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.onboarding.total_onboardig }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.onboarding.onboarding_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.onboarding.onboarding_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/house-2.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Add Gudang</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.partner_add_gudang.total_user_gudang }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.partner_add_gudang.gudang_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.partner_add_gudang.gudang_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/box-add.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Add Produk</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.partner_add_product.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.partner_add_product.total_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.partner_add_product.total_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/status-up.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Add Order</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.potensial.total_potensial }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.potensial.potensial_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.potensial.potensial_yesterday }} Kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/chart.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Pickup New User</span>
              </div>
              <div
                style="cursor:pointer"
                class="border-[#E2E2E2] p-[3px] rounded-[6px] border-solid border-2"
                @click="$router.push('/admin-dashboard-komship/user-growth-performance')"
              >
                <img src="https://storage.googleapis.com/komerce/assets/icons/arrow-right-2.svg">
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.aktif.total_user_active }}
                <div class="text-sm">Lifetime</div>
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.aktif.active_today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.aktif.active_yesterday }} Kemarin
                </div>
              </div>
            </div>
            <div class="flex space-x-2">
              <div class="text-sm text-black">
                Bulan lalu:
                <span
                  class="text-[#08A0F7]"
                >+ {{ data.aktif.active_user_last_month }}</span>
              </div>
              <div class="text-sm text-black">
                Bulan ini:
                <span
                  class="text-[#34A270]"
                >+ {{ data.aktif.active_user_this_month }}</span>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl shadow p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/monitor-mobbile.svg"
                  alt="Komerce"
                >
                <span class="text-xl text-black ml-[5px]">Pickup Bulan Ini</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="my-[10px] flex"
            >
              <span class="text-black text-[25px] border-r pr-2">
                {{ data.user_aktif.total_user_aktive }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  {{ data.user_aktif.new_user_active }}% User Baru ({{ data.user_aktif.sum_new_active_user }})
                </div>
                <div class="text-[#08A0F7]">
                  {{ data.user_aktif.user_active_last_month }}% User Lama ({{ data.user_aktif.sum_old_active_user }})
                </div>
              </div>
            </div>
            <MonthlyPicker
              v-model="filter"
              date-format="MMM YYYY"
              place-holder="Pilih Bulan"
              :month-labels="monthlabel"
              :max="maxDatePicker"
              style="margin: auto"
            />
          </div>
        </div>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCard, BSpinner } from 'bootstrap-vue'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import MonthlyPicker from 'vue-monthly-picker'
import { END_DATE_OF_MONTH, LABELMONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'

export default {
  components: {
    BRow, BCard, BSpinner, MonthlyPicker,
  },
  data() {
    return {
      data: {},
      loading: false,
      filter: this.$moment(),
      monthlabel: LABELMONTH,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    filter: {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter),
        end_date: END_DATE_OF_MONTH(this.filter),
      }
      const url = '/v1/admin/dashboard/users-growth-performance'
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.data = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
  },
}
</script>
