<template>
  <BCard>
    <strong class="text-black font-medium text-2xl">Ekspedisi</strong>
    <BRow class="mt-1">
      <BCol lg="6">
        <BCard style="border: 1px solid #e2e2e2; border-radius: 14px">
          <div class="text-black font-bold text-xl">
            Performa Succsess Rate Ekspedisi
          </div>
          <MonthlyPicker
            v-model="filter.ratting"
            date-format="MMM YYYY"
            place-holder="30 Hari Terakhir"
            class="filter"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            style="width: 50px; margin-top: 5px"
          />
          <BOverlay
            :show="loading.ratting"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="itemSuccessHandling"
              :fields="fieldSuccessHandling"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1"
            >
              <template #cell(retur_rate)="data">
                <div style="min-width: 200px !important">
                  <span :class="handleLableReturRate(data)">{{ handleReturRate(data.item) }}</span>
                </div>
              </template>
              <template #cell(status)="data">
                <div
                  :class="handleClassStatus(data)"
                  style="min-width: 120px; border-radius: 20px"
                >
                  {{ handleLabelStatus(data) }}
                </div>
              </template>
              <template #cell(success_rate)="data">
                <div style="min-width: 200px !important">
                  <span>{{ handleSuccessRate(data.item) }}</span>
                </div>
              </template>
            </BTable>
          </BOverlay>
        </BCard>
      </BCol>
      <BCol lg="6">
        <BCard style="border: 1px solid #e2e2e2; border-radius: 14px">
          <div class="text-black font-bold text-xl">
            Performa Kecepatan Pengiriman Ekspedisi
          </div>
          <MonthlyPicker
            v-model="filter.delivery"
            date-format="MMM YYYY"
            place-holder="30 Hari Terakhir"
            class="filter"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            style="width: 50px; margin-top: 5px"
          />
          <BOverlay
            :show="loading.delivery"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="itemFastHandling"
              :fields="fieldFastHandling"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1"
              sticky-header
            >
              <template #cell(cod)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(non_cod)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(average)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(service)="data">
                <span>{{ data.item.service_alias }}</span>
              </template>
            </BTable>
          </BOverlay>
        </BCard>
      </BCol>
    </BRow>
    <ChartShippingCost />
  </BCard>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
} from 'bootstrap-vue'
import MonthlyPicker from 'vue-monthly-picker'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import isEmpty from 'lodash/isEmpty'
import { END_DATE_OF_MONTH, LABELMONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'
import { komshipAxiosIns } from '@/libs/axios'
import { fieldSuccessHandling, fieldFastHandling } from '../config'
import ChartShippingCost from './ChartShippingCost.vue'

export default {
  components: {
    BCard, BRow, BCol, BTable, MonthlyPicker, ChartShippingCost,
  },
  data() {
    return {
      itemSuccessHandling: [],
      itemFastHandling: [],
      fieldSuccessHandling,
      fieldFastHandling,
      monthlabel: LABELMONTH,
      filter: {
        ratting: this.$moment(),
        delivery: this.$moment(),
      },
      loading: {
        ratting: true,
        delivery: true,
      },
    }
  },
  computed: {
    maxDatePicker() { return this.$moment().endOf('month') },
  },
  watch: {
    'filter.ratting': {
      handler() {
        this.getRatting()
      },
    },
    'filter.delivery': {
      handler() {
        this.getDelivery()
      },
    },
  },
  mounted() {
    this.getRatting()
    this.getDelivery()
  },
  methods: {
    async getRatting() {
      this.loading.ratting = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.ratting),
        end_date: END_DATE_OF_MONTH(this.filter.ratting),
      }
      const url = '/v1/admin/dashboard/success-rate-shipping'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.itemSuccessHandling = data
          this.loading.ratting = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading.ratting = false
        })
    },
    async getDelivery() {
      this.loading.delivery = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.delivery),
        end_date: END_DATE_OF_MONTH(this.filter.delivery),
      }
      const url = '/v1/admin/dashboard/shipping-delivery-performance'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.itemFastHandling = data
          this.loading.delivery = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading.delivery = false
        })
    },
    handleReturRate(value) {
      const data = (value.total_retur / value.total_order) * 100
      return `${data.toFixed(1)}% (${value.total_retur}/${value.total_order})`
    },
    handleSuccessRate(value) {
      const data = (value.total_diterima / value.total_order) * 100
      return `${data.toFixed(1)}% (${value.total_diterima}/${value.total_order})`
    },
    handleLableReturRate(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'very-good'
      }
      if (data <= 2) {
        return 'very-good'
      }
      if (data >= 2 && data <= 5) {
        return 'good'
      }
      if (data >= 5 && data <= 15) {
        return 'medium'
      }
      if (data >= 15 && data <= 25) {
        return 'bad'
      }
      if (data >= 25) {
        return 'very-bad'
      }
      return 'very-good'
    },
    handleClassStatus(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'lableStatusVeryGood'
      }
      if (data <= 2) {
        return 'lableStatusVeryGood'
      }
      if (data >= 2 && data <= 5) {
        return 'lableStatusGood'
      }
      if (data >= 5 && data <= 15) {
        return 'lableStatusMedium'
      }
      if (data >= 15 && data <= 25) {
        return 'lableStatusBad'
      }
      if (data >= 25) {
        return 'lableStatusVeryBad'
      }
      return 'lableStatusVeryGood'
    },
    handleLabelStatus(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'Sangat bagus'
      }
      if (data <= 2) {
        return 'Sangat bagus'
      }
      if (data >= 2 && data <= 5) {
        return 'Bagus'
      }
      if (data >= 5 && data <= 15) {
        return 'Sedang'
      }
      if (data >= 15 && data <= 25) {
        return 'Buruk'
      }
      if (data >= 25) {
        return 'Sangat buruk'
      }
      return 'Sangat bagus'
    },
  },
}
</script>
<style lang="scss" scoped src="./HistoryRetur.scss" />
