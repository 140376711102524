<template>
  <BCard>
    <BRow>
      <BCol lg="6">
        <BCard class="border rounded-lg">
          <div class="text-black text-xl">
            Performa Transaksi Partner
          </div>
          <BRow class="justify-end my-1">
            <BFormSelect
              v-model="expedition"
              :options="expeditionList"
              value-field="shipping_name"
              text-field="shipping_name"
              placeholder="Pilih Status"
              class="w-auto mr-[5px]"
            >
              <template #first>
                <b-form-select-option
                  :value="expeditionList.map(item => item.shipping_name).join(',')"
                >
                  Semua
                </b-form-select-option>
              </template>
            </BFormSelect>
            <MonthlyPicker
              v-model="filter.chart"
              :month-labels="monthlabel"
              :max="maxDatePicker"
              date-format="MMM YYYY"
            />
          </BRow>
          <BOverlay
            :show="loading.chart"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <ApexChart
              ref="chart"
              type="area"
              :options="optionChartTransaction"
              :series="seriesChartTransaction"
            />
          </BOverlay>
        </BCard>
      </BCol>
      <BCol lg="6">
        <BCard class="border rounded-lg">
          <div class="text-black text-xl">
            Top Partner Transaksi
          </div>
          <BRow class="justify-end my-1">
            <BFormSelect
              v-model="payment"
              :options="optionExpedition"
              class="w-auto mr-[5px]"
            />
            <MonthlyPicker
              v-model="filter.transaction"
              :month-labels="monthlabel"
              date-format="MMM YYYY"
            />
          </BRow>
          <BOverlay
            :show="loading.transaction"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="items"
              :fields="fields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              sticky-header
            >
              <template #cell(transaction_cod)="data">
                <div style="min-width: 120px !important">
                  <span class="text-[#34a770]">{{ IDR(data.item.transaction_cod) }}</span>
                </div>
              </template>
            </BTable>
          </BOverlay>
          <BCard class="border rounded-lg mb-0">
            <div class="flex items-center">
              <div class="mr-1 text-black font-bold">
                Total Nilai Transaksi
              </div>
              <img
                v-b-tooltip.hover.top="'Total nilai transaksi di bulan tersebut'"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt="Komerce"
                class="cursor-pointer"
              >
            </div>
            <BSpinner
              v-if="loading.transaction"
              small
              variant="primary"
            />
            <span
              v-else
              class="text-[#34a770] text-3xl"
            >
              {{ IDR(totalTransaction) }}
            </span>
          </BCard>
        </BCard>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BCol, BFormSelect, BSpinner, BOverlay,
} from 'bootstrap-vue'
import MonthlyPicker from 'vue-monthly-picker'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { IDR } from '@/libs/currency'
import { mapState } from 'vuex'
import { END_DATE_OF_MONTH, LABELMONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'
import ApexChart from 'vue-apexcharts'
import {
  columnPartnerTransaction, optionChartTransaction, optionExpedition,
} from '../config'

export default {
  components: {
    BCard, BRow, BCol, MonthlyPicker, BSpinner, BFormSelect, BOverlay, ApexChart,
  },
  data() {
    return {
      items: [],
      fields: columnPartnerTransaction,
      loading: {
        chart: false,
        transaction: false,
      },
      IDR,
      totalTransaction: 0,
      payment: 1,
      expedition: '',
      optionExpedition,
      filter: {
        transaction: this.$moment(),
        chart: this.$moment(),
      },
      monthlabel: LABELMONTH,
      optionChartTransaction,
      seriesChartTransaction: [],
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
    maxDatePicker() { return this.$moment().endOf('month') },
  },
  watch: {
    payment: {
      handler() { this.getTransaction() },
    },
    'filter.transaction': {
      handler() { this.getTransaction() },
    },
    'filter.chart': {
      handler() { this.getChart() },
    },
    expedition: {
      handler() { this.getChart() },
    },
  },
  async mounted() {
    this.expedition = this.expeditionList.map(item => item.shipping_name).join(',')
    await this.getTransaction()
    await this.getChart()
  },
  methods: {
    async getTransaction() {
      this.loading.transaction = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.transaction),
        end_date: END_DATE_OF_MONTH(this.filter.transaction),
        payment_option: this.payment,
      }
      const url = '/v1/admin/dashboard/topPartnerTransaction'
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { partners, grand_total } = res.data.data
          this.items = partners
          this.loading.transaction = false
          // eslint-disable-next-line camelcase
          this.totalTransaction = grand_total
        })
        .catch(err => {
          this.loading.transaction = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: err.response.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async getChart() {
      this.loading.chart = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.chart),
        end_date: END_DATE_OF_MONTH(this.filter.chart),
        expedition_option: this.expedition,
      }
      const url = '/v1/admin/dashboard/partnerTransactionPerformance'
      await komshipAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loading.chart = false
          this.seriesChartTransaction = [
            {
              name: 'Total',
              data: data.total.map(e => e.total),
            },
            {
              name: 'Non - COD',
              data: data.non_cod.map(e => e.total_noncod),
            },
            {
              name: 'COD',
              data: data.cod.map(e => e.total_cod),
            },
            {
              name: 'Order',
              data: data.total.map(e => e.total_order),
            },
            {
              name: 'Partner',
              data: data.total.map(e => e.total_partner),
            },
          ]
          this.optionChartTransaction = {
            ...this.optionChartTransaction,
            xaxis: {
              ...this.optionChartTransaction.xaxis,
              categories: data.total.map(e => e.day),
            },
          }
          this.$nextTick(() => {
            this.$refs.chart.chart.hideSeries('Order')
            this.$refs.chart.chart.hideSeries('Partner')
          })
        })
        .catch(err => {
          this.loading.chart = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: err.response.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          }, 2000)
        })
    },
  },
}
</script>
