import IconVeryGood from '@/assets/images/svg/very-good.svg'
import IconGood from '@/assets/images/svg/good.svg'
import IconMedium from '@/assets/images/svg/medium.svg'
import IconBad from '@/assets/images/svg/bad.svg'
import IconVeryBad from '@/assets/images/svg/very-bad.svg'
import { IDR } from '@/libs/currency'
import moment from 'moment'
import { END_DATE_OF_MONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'

const colorDefault = ['#08A0F7', '#34A770', '#FBA63C']

export const indicatorRatioRetur = [
  {
    id: 1,
    name: 'Sangat Bagus',
    img: IconVeryGood,
  },
  {
    id: 2,
    name: 'Bagus',
    img: IconGood,
  },
  {
    id: 3,
    name: 'Sedang',
    img: IconMedium,
  },
  {
    id: 4,
    name: 'Buruk',
    img: IconBad,
  },
  {
    id: 5,
    name: 'Sangat Buruk',
    img: IconVeryBad,
  },
]

export const fieldRasioRetur = [
  {
    key: 'city_name',
    label: 'Kota / Kab',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'jne',
    label: 'JNE',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'ideexpress',
    label: 'IDExpress',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'sicepat',
    label: 'SiCepat',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'sap',
    label: 'SAP',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'average',
    label: 'Rata-rata',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'status',
    label: 'Status',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const fieldSuccessHandling = [
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'bg-white align-middle text-black p-0',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  {
    key: 'retur_rate',
    label: 'Retur Rate',
    class: 'bg-white align-middle text-black p-0 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  {
    key: 'status',
    label: 'Status',
    class: 'bg-white align-middle text-black p-0 text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  {
    key: 'success_rate',
    label: 'Success Rate',
    class: 'bg-white text-center text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
]

export const fieldFastHandling = [
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'bg-white p-1 align-middle text-black text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'service',
    label: 'Service',
    class: 'bg-white p-1 align-middle text-black text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'cod',
    label: 'COD',
    class: 'bg-white p-1 align-middle text-black text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'non_cod',
    label: 'Non-COD',
    class: 'bg-white p-1 align-middle text-black text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'average',
    label: 'Rata-rata',
    class: 'bg-white p-1 align-middle text-black text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
]

export const columnShippingExpedition = [
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'detail',
    label: '',
    class: 'bg-white align-middle text-black p-0',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  {
    key: 'total_order',
    label: 'Order',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    sortable: true,
  },
  {
    key: 'shipping_cost',
    label: 'Nilai Ongkir',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    sortable: true,
  },
]

export const shiipingName = ['IDEXPRESS', 'JNE', 'SAP', 'SICEPAT']
export const optionExpedition = [
  {
    text: 'COD',
    value: 1,
  },
  {
    text: 'Non - COD',
    value: 2,
  },
  {
    text: 'COD & Non COD',
    value: 3,
  },
]
export const optionChartShipping = {
  chart: {
    type: 'area',
    stacked: false,
    height: 260,
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  dataLabels: { enabled: false },
  markers: { size: 0 },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  stroke: { curve: 'smooth' },
  yaxis: {
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#000000',
      },
      offsetX: 0,
      formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
    },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 14,
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    categories: [],
    labels: {
      formatter: (val, timestamp) => moment(new Date(timestamp)).format('DD'),
    },
  },
  tooltip: {
    custom: ({ dataPointIndex, w }) => {
      let htmlRender = ''
      const arrayData = [...w.globals.series]
      arrayData.forEach((x, idx) => {
        if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
          htmlRender += ''
        } else {
          htmlRender += `<p class="my-0 mt-1" style="color: ${colorDefault[idx]};">
                  ${w.globals.seriesNames[idx]}<span class="text-black"> ${IDR(x[dataPointIndex] || 0)}
                </p>`
        }
      })
      return `<div class="d-grid px-1 rounded align-items-center">
              ${htmlRender}
              <br/>
            </div>`
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  colors: colorDefault,
  noData: { text: 'NO DATA' },
}

export const optionChartTransaction = {
  chart: {
    type: 'area',
    stacked: false,
    height: 425,
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  dataLabels: { enabled: false },
  markers: { size: 0 },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  stroke: { curve: 'smooth' },
  yaxis: {
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#000000',
      },
      offsetX: 0,
      formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
    },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 14,
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    categories: [],
    labels: {
      formatter: (val, timestamp) => moment(new Date(timestamp)).format('DD'),
    },
  },
  tooltip: {
    custom: ({ dataPointIndex, w }) => {
      let htmlRender = ''
      const partner = w.globals.collapsedSeries[1].data[dataPointIndex]
      const order = w.globals.collapsedSeries[0].data[dataPointIndex]
      const arrayData = [...w.globals.series]
      arrayData.forEach((x, idx) => {
        if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
          htmlRender += ''
        } else {
          htmlRender += `<p class="my-0 mt-1" style="color: ${colorDefault[idx]};">
                  <span>${w.globals.seriesNames[idx]}</span> <span class="text-black">
                    ${w.globals.seriesNames[idx] === 'Order' ? x[dataPointIndex] : IDR(x[dataPointIndex] || 0)}</span>
                    ${w.globals.seriesNames[idx] === 'Total' ? `<span class="text-gray-600"> <br/>${w.globals.seriesNames[3]} ${order} (${partner} Partner)</span>` : ''}
                </p>`
        }
      })

      return `<div class="d-grid px-1 rounded align-items-center">
              ${htmlRender}
              <br/>
            </div>`
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
  },
  colors: [...colorDefault, '#D3067B'],
  noData: { text: 'NO DATA' },
}

export const columnPartnerTransaction = [
  {
    key: 'partner_name',
    label: 'Partner',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'total_order',
    label: 'Order',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    sortable: true,
  },
  {
    key: 'transaction_cod',
    label: 'Nilai Transaksi',
    class: 'bg-white align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    sortable: true,
  },
]
